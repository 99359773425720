body {
  font-family: 'IBM Plex Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-8c9c78 {
  background-color: #8C9C78;
}

.bg-d2e28a {
  background-color: #D2E28A;
}

.bg-d9d9d9 {
  background-color: #D9D9D9;
}

.bg-bcc2d2 {
  background-color: #BCC2D2;
}

.bg-f5f7fc {
  background-color: #F5F7FC;
}

.bg-e8ebf3 {
  background-color: #E8EBF3;
}

.bg-b6e28a {
  background-color: #B6E28A;
}

.remove-outline {
  outline: none;
}